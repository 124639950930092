import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

export const router: Routes = [
	{path: '', redirectTo: 'gis', pathMatch: 'full'},
    {
        path: 'gis',
        loadChildren: 'src/app/components/gis/gis.module#GisModule'
    },{
        path: 'register',
        loadChildren: 'src/app/register/register.module#RegisterModule'
    },
];

export const appRouter: ModuleWithProviders = RouterModule.forRoot(router, {useHash: true});