import { InjectionToken } from "@angular/core";

export const APP_DI_CONFIG: any = {

	// API_BASE_URL: 'https://125.227.157.247:8002/api/',
	//WEB_BASE_URL: 'https://125.227.157.247:8002/', //前端網站位置
	//PROXY_URL: 'https://125.227.157.247:8002/image_proxy.php?url=',

	// API_BASE_URL: 'https://archaeogis.ihp.sinica.edu.tw/api/',
	// WEB_BASE_URL: 'https://archaeogis.ihp.sinica.edu.tw/', //前端網站位置
	// PROXY_URL: 'https://archaeogis.ihp.sinica.edu.tw/image_proxy.php?url=',

	API_BASE_URL: '/api/',
	WEB_BASE_URL: '/', //前端網站位置
	PROXY_URL: '/image_proxy.php?url=',

	RECPTCHA2_SITE_KEY: '6Lej06sUAAAAAE_xDTtBNHb4mFQHcXlNNiraBrLZ',

	/** GIS CONFIG **/
	TAIWAN_COORD: [120.974193, 23.918312],
	ASIA_COORD: [116.236051, 34.595336],
	EUROPE_COORD: [29.476213, 47.392246],
	AFRICA_COORD: [37.447383, -7.774660],
	WEST_ASIA_COORD: [92.908285, 33.147324],
	NORTH_AMERICA_COORD: [-110.025960, 47.669511],
	LATIN_AMERICA_COORD: [-68.261547, -14.641757],

	TAIWAN_GEOSJON: '{"type":"FeatureCollection","features":[{"type":"Feature","properties":{},"geometry":{"type":"Polygon","coordinates":[[[121.45935058593749,25.25463261974945],[120.91552734375,24.816653556469955],[120.2838134765625,23.926013033021192],[120.02014160156249,23.089838367476705],[120.50903320312501,22.19757745335104],[120.860595703125,21.88188980762927],[121.35498046875,23.008964269673108],[121.89331054687499,24.711905448466087],[121.94274902343749,25.06569718553588],[121.69006347656249,25.279470734081812],[121.45935058593749,25.25463261974945]]]}}]}',

	/** isMobile **/
	IS_MOBILE: (navigator.userAgent.match(/Android/i)
	|| navigator.userAgent.match(/webOS/i)
	|| navigator.userAgent.match(/iPhone/i)
	|| navigator.userAgent.match(/iPad/i)
	|| navigator.userAgent.match(/iPod/i)
	|| navigator.userAgent.match(/BlackBerry/i)
	|| navigator.userAgent.match(/Windows Phone/i))
	|| screen.width <= 1024,

	IS_iOS: () => {
		return navigator.userAgent.match(/iPhone|iPad|iPod|Mac/i)
	},
};

export let APP_CONFIG = new InjectionToken< any >( 'app.config' );

