import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NgModule, Injector } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { appRouter } from './app.router';
import { GestureConfig } from '@angular/material';

import { APP_CONFIG, APP_DI_CONFIG } from "./app-config/app-config.constants";;

@NgModule({ 
  declarations: [
    AppComponent
  ],  
  imports: [ 
	  BrowserAnimationsModule,
    BrowserModule,
    appRouter,
  ], 
  providers: [
    {
		provide: APP_CONFIG,
		useValue: APP_DI_CONFIG
    },
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
    static injector: Injector;
    constructor(injector: Injector) {
        AppModule.injector = injector;
    }
}
